import React, { useEffect, useRef, useState, MouseEvent } from "react";
import Hero, { HeroProps } from "./hero";
import "./heroSlider.scss";
import Flickity from "react-flickity-component";
import classNames from "classnames";
import gsap from "gsap";

export interface HeroSliderProps {
  slider: HeroProps[];
}

interface Props extends HeroSliderProps {}

const HeroSlider: React.FC<Props> = (props) => {
  const sliderRef = useRef<any | null>(null);
  const [sliderIndex, setSliderIndex] = useState<number>(0);

  const flickityOptions = {
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false,
    initialIndex: 0,
    autoPlay: 5000,
    adaptiveHeight: true,
  };

  useEffect(() => {
    const handleSliderChange = (e: any) => {
      setSliderIndex(e);
    };
    !!sliderRef.current && sliderRef.current.on("change", handleSliderChange);
    return () => {
      !!sliderRef.current && sliderRef.current.off("change", handleSliderChange);
    }
  }, []);

  const handleDotClick = (e: MouseEvent) => {
    const target = e.currentTarget;
    const index = parseInt(target.getAttribute('data-index') || '');
    !!sliderRef.current && !!sliderRef.current.select(index);
  }

  return (
    <div className="hero-slider">
      <Flickity
        options={flickityOptions}
        flickityRef={(ref) => (sliderRef.current = ref)}
        className="hero-slider__slider"
      >
        {props.slider.map((item, key) => (
          <Hero {...item} key={key} sliderItem data-preload={key === 0 ? true : false} withDots isInActive={key!==sliderIndex} />
        ))}
      </Flickity>
      <div className="hero-arrow">
        <div className="hero-arrow__inner" onClick={() => {
          gsap.to(window, {
            duration: 0.4,
            scrollTo: window.innerHeight,
          });
        }} />
      </div>
      <div className="hero-slider-dotnav">
        {props.slider.map((item, key) => (
          <button
            className={classNames("hero-slider-dotnav__item", {
              "is-active": key === sliderIndex,
            })}
            key={key}
            data-index={key}
            onClick={handleDotClick}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroSlider;
