import Img, { ImgProps } from "components/img/img";
import { TransLink } from "components/trans/transLink";
import moment from "moment";
// import "moment/locale/de";
import React from "react";
import { getFormatedDate } from "utils/date.utils";
import "./teaser.scss";

export interface TeaserData {
  id: string;
  uri: string;
  slug?: string;
  title: string;
  postNews: {
    titleForOverview?: string;
    newsSubline?: string;
  }
  featuredImage: {
    node: ImgProps;
  };
  terms?: {
    nodes: {
      name: string;
      categoryFields: {
        color: string;
      }
      taxonomyName: string;
    }[];
  };
  event?: {
    date: string;
  }
}

interface Props extends TeaserData {}

const Teaser: React.FC<Props> = (props) => {

  return (
    <div className="teaser">
      <div className="teaser-image">
        {!!props.featuredImage && <Img {...props.featuredImage.node} />}
      </div>
      <div className="teaser__content">
        {!!props.terms && (
          <div className="teaser__subline">
            {props.terms.nodes.map((item, key) => {
              return item.taxonomyName === 'category' && <span key={key} style={{backgroundColor: `#${item.categoryFields?.color || '003658'}`}}>{item.name}</span>
            })}
          </div>
        )}
        {!!props.postNews?.newsSubline && (
          <div className="teaser__subline">{props.postNews?.newsSubline.replaceAll('<p>', '').replaceAll('</p>', '')}</div>
        )}
        {!!props.event && (
          <div className="teaser__subline">Agenda - {moment(props.event.date).locale('de').format("DD. MMMM YYYY")}</div>
        )}
        <h4>{props.postNews?.titleForOverview || props.title}</h4>
        <TransLink to={!!props.slug ? `/agenda/#${props.slug}` : props.uri} className="teaser__link link-text">Weiterlesen</TransLink>
      </div>
      <TransLink to={!!props.slug ? `/agenda/#${props.slug}` : props.uri} className="teaser__area" />
    </div>
  );
};

export default Teaser;
