import React from 'react';
import './mediathekTeaser.scss';
import parse from 'html-react-parser';

export type MediaTeaserProps = {
  title: string;
  AcfMediathek: {
    videoEmbedUrl?: string;
    podigeeEmbedUrl?: string;
    text: string;
  };
};

interface Props extends MediaTeaserProps {}

const MediathekTeaser: React.FC<Props> = (props) => {
  return (
    <div className="mediathek-teaser">
      {!!props.AcfMediathek.videoEmbedUrl && (
        <div className="mediathek-teaser__container is-youtube">
          {props.AcfMediathek.videoEmbedUrl.startsWith('<iframe') ? (
            parse(props.AcfMediathek.videoEmbedUrl)
          ) : (
            <iframe
              src={props.AcfMediathek.videoEmbedUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              height="100%"
              width="100%"
            />
          )}
        </div>
      )}
      {!!props.AcfMediathek.podigeeEmbedUrl && (
        <div className="mediathek-teaser__container is-podigee">
          {props.AcfMediathek.podigeeEmbedUrl.startsWith('<iframe') ? (
            parse(props.AcfMediathek.podigeeEmbedUrl)
          ) : (
            <iframe src={props.AcfMediathek.podigeeEmbedUrl} style={{ border: 0 }} height="100" width="100%" />
          )}
        </div>
      )}

      <h4>{props.title}</h4>
      <p>{props.AcfMediathek.text}</p>
    </div>
  );
};

export default MediathekTeaser;
