import { ImgProps } from 'components/img/img';
import { graphql, StaticQuery, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

export interface SEOProps {
  title: string;
  metaDesc: string;
  opengraphImage: ImgProps;
}

interface Props extends SEOProps {}

const SEO: React.FC<Props> = (props) => {
  const isBrowser = typeof window !== 'undefined';
  const url = isBrowser ? window.location : '';

  return (
    <Helmet
      htmlAttributes={{
        lang: 'de-DE',
      }}
    >
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      <meta property="og:title" content={props.title} />
      <meta name="description" content={props.metaDesc} />
      <meta property="og:description" content={props.metaDesc} />
      {!!props.opengraphImage && props.opengraphImage.localFile && props.opengraphImage.localFile.publicURL && (
        <meta property="og:image" content={'https://www.iwp.swiss' + props.opengraphImage.localFile.publicURL} />
      )}
      <meta property="og:url" content={`${url}`} />
      <meta name="twitter:card" content="summary_large_image"></meta>
    </Helmet>
  );
};

export default SEO;
