import React, { useEffect, useRef, useState } from 'react';
import { pageContextProps } from 'utils/props.utils';
import './frontpage.template.scss';
import imagesLoaded from 'imagesloaded';
import { handlePageLoaded, TransLink } from 'components/trans/transLink';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import HeroSlider, { HeroSliderProps } from 'blocks/hero/heroSlider';
import { FactsGallery } from 'components/facts/gallery/factsGallery';
import Teaser, { TeaserData } from 'components/teaser/teaser';
import Img, { ImgProps } from 'components/img/img';
import parse from 'html-react-parser';
import SEO from 'components/seo/seo';
import HeroSmall from 'blocks/heroSmall/heroSmall';
import MediathekTeaser, { MediaTeaserProps } from 'components/mediathekTeaser/mediathekTeaser';
import { isApp } from 'layout/layout';

interface Props {
  pageContext: pageContextProps;
  data: {
    page: {
      heroSlider: HeroSliderProps;
      frontpage: {
        latestPosts: {
          headline: string;
          items: {
            item: TeaserData;
          }[];
        };
        fokus: {
          headline: string;
          image: ImgProps;
          link: {
            url: string;
            target: string;
          };
        };
        institutIntro: {
          headline: string;
          text: string;
          link: {
            link: string;
          };
          linkLabel: string;
        };
        faktenGallery?: {
          fakten: {
            AcfFacts: {
              id: string;
              text: string;
              link: string;
              sources: {
                link: string;
                text: string;
              }[];
            };
          }[];
          backgroundColor: string;
          hasCustomColor: boolean;
          backgroundColorCustom: string;
        };
        backgroundImage: ImgProps;
      };
    };
    mediathek: {
      edges: {
        node: MediaTeaserProps;
      }[];
    };
    latestFacts: {
      nodes: {
        AcfFacts: {
          id: string;
          text: string;
          link: string;
          sources: {
            link: string;
            text: string;
          }[];
        };
      };
      totalCount: number;
    }
  };
}

const FrontpageTemplate: React.FC<Props> = (props) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [showMediathek, setShowMediathek] = useState<boolean>(false);

  // console.log('latestFacts', props.data.latestFacts.nodes);

  const selectedFacts = props.data.page.frontpage.faktenGallery?.fakten || [];
  const selectedOrLatestFacts = [...selectedFacts, ...props.data.latestFacts.nodes].slice(0, 10);

  useEffect(() => {
    const main = mainRef.current;
    !!main && imagesLoaded(document.querySelectorAll('[data-preload]'), handlePageLoaded);
    setIsLoaded(true);
  }, []);

  // As of now, no Mediathek on frontpage

  // useEffect(() => {
  //   setShowMediathek(isApp());
  // }, []);

  return (
    <div
      className={classNames('frontpage-template', {
        'is-loading-complete': isLoaded,
      })}
      id={props.pageContext.id}
      ref={mainRef}
    >
      <SEO {...props.pageContext.seo} />
      <HeroSlider {...props.data.page.heroSlider} />
      <div className="frontpage-modules">
        {/* LATEST POSTS */}
        {showMediathek && (
          <section className="frontpage-latest">
            <div className="section-header">
              <h5 className="section-header__title">Mediathek</h5>
            </div>
            <div className="wrapper">
              <div className="grid-row">
                {props.data.mediathek.edges.map((item, key) => (
                  <div className="grid-cell sm-12 md-6 lg-4" key={key}>
                    <MediathekTeaser {...item.node} />
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}
        {/* FACTS */}
          <FactsGallery 
            facts={selectedOrLatestFacts} 
            maxFacts={props.data.latestFacts.totalCount} 
            backgroundColor={props.data.page.frontpage.faktenGallery?.backgroundColor} 
            hasCustomColor={props.data.page.frontpage.faktenGallery?.backgroundColor} 
            backgroundColorCustom={props.data.page.frontpage.faktenGallery?.backgroundColorCustom} />
        {/* LATEST POSTS */}
        <section className="frontpage-latest">
          <div className="section-header">
            <h5 className="section-header__title">{props.data.page.frontpage.latestPosts.headline}</h5>
          </div>
          <div className="wrapper">
            <div className="grid-row">
              {props.data.page.frontpage.latestPosts.items.map((item, key) => (
                <div className="grid-cell sm-12 md-6 lg-4" key={key}>
                  <Teaser {...item.item} />
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* FOCUS */}
        <section className="frontpage-fokus">
          <div className="section-header">
            <h5 className="section-header__title">Fokus</h5>
          </div>
          <HeroSmall {...props.data.page.frontpage.fokus} />
        </section>
      
        {/* MEDIATHEK */}
        <section className="frontpage-institut">
          <div className="wrapper">
            <div className="frontpage-institut__inner">
              <h1 className="h3">{parse(props.data.page.frontpage.institutIntro.headline)}</h1>
              <p className="frontpage-institut__copy">{parse(props.data.page.frontpage.institutIntro.text)}</p>
              <TransLink to={props.data.page.frontpage.institutIntro.link.link} className="link-text">
                {props.data.page.frontpage.institutIntro.linkLabel}
              </TransLink>
            </div>
          </div>
        </section>
        <Img {...props.data.page.frontpage.backgroundImage} className="frontpage-background" />
      </div>
    </div>
  );
};

export default FrontpageTemplate;

export const query = graphql`
  query ($id: String!, $language: String!) {
    page: wpPage(id: { eq: $id }) {
      heroSlider {
        slider {
          headline
          text
          headlineTight
          image {
            ...BGImage
          }
          video {
            desktop {
              sourceUrl
              poster {
                ...OGImage
              }
            }
            mobile {
              sourceUrl
              poster {
                ...OGImage
              }
            }
          }
          mediaPosition {
            left
            top
          }
          pageLinkLabel
          pageLink {
            ... on WpPage {
              link
            }
            ... on WpPost {
              link
            }
            ... on WpEvent {
              slug
            }
          }
        }
      }
      frontpage {
        latestPosts {
          headline
          items {
            item {
              ... on WpPost {
                id
                title
                uri
                postNews {
                  titleForOverview
                }
                featuredImage {
                  node {
                    ...FeaturedImage
                  }
                }
                terms {
                  nodes {
                    name
                    slug
                    taxonomyName
                    ... on WpCategory {
                      categoryFields {
                        color
                      }
                    }
                  }
                }
              }
              ... on WpEvent {
                id
                title
                uri
                slug
                featuredImage {
                  node {
                    ...FeaturedImage
                  }
                }
                event {
                  date
                }
              }
            }
          }
        }
        fokus {
          headline
          image {
            ...ContentImage
          }
          link {
            url
            target
          }
        }
        institutIntro {
          headline
          text
          link {
            ... on WpPage {
              link
            }
          }
          linkLabel
        }
        faktenGallery {
          fakten {
            ... on WpFact {
              id
              AcfFacts {
                id
                text
                link
                sources {
                  link
                  text
                }
              }
            }
          }
          backgroundColor
          hasCustomColor
          backgroundColorCustom
        }
        backgroundImage {
          ...BGImage
        }
      }
    }
    mediathek: allWpMediathek(limit: 3, sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          title
          AcfMediathek {
            videoEmbedUrl
            podigeeEmbedUrl
            text
          }
        }
      }
    }
    latestFacts: allWpFact(limit: 10, sort: {order: DESC, fields: date}) {
      nodes {
        AcfFacts {
          id
          text
          link
          sources {
            link
            text
          }
        }
      }
      totalCount
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
