import { graphql } from "gatsby";
import React from "react";

export interface ImgProps {
  altText?: string;
  localFile?: {
    childImageSharp?: {
      gatsbyImageData: {
        images: {
          fallback: {
            src: string;
            srcSet: string;
            sizes: string;
          }
        }
      }
    };
    publicURL: string;
  };
}

interface Props extends ImgProps {
  className?: string;
}

export const query = graphql`
  fragment BGImage on WpMediaItem {
    altText
    localFile {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
      publicURL
    }
  }
  fragment TeamImage on WpMediaItem {
    altText
    localFile {
      childImageSharp {
        gatsbyImageData(width: 1600, quality: 80)
      }
      publicURL
    }
  }
  fragment FeaturedImage on WpMediaItem {
    altText
    localFile {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 80)
      }
      publicURL
    }
  }
  fragment ContentImage on WpMediaItem {
    altText
    localFile {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
      publicURL
    }
  }
  fragment OGImage on WpMediaItem {
    altText
    localFile {
      publicURL
    }
  }
`

const Img: React.FC<Props> = (props) => {
  if (!!props.localFile) {
    if (!!props.localFile.childImageSharp) {
      return (
        <img
          src={
            props.localFile.childImageSharp.gatsbyImageData.images.fallback.src
          }
          alt={props.altText}
          className={props.className}
        />
      );
    } else if (!!props.localFile.publicURL) {
      return (
        <img
          src={props.localFile.publicURL}
          alt={props.altText}
          className={props.className}
        />
      );
    }
  }

  return null;
};

export default Img;
