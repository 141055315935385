import React, { useEffect, useRef, useState } from 'react';
import { TransLink } from 'components/trans/transLink';
import { Link } from 'gatsby';

import './factsGallery.scss';
import { factsDataType } from 'data/facts.data';
import { FactsShare } from '../share/factsShare';
import FactsBlock from '../factsBlock';
import parse from 'html-react-parser';
import Flickity from 'react-flickity-component';
import ArrowLeft from '../../../assets/arrow-left.svg';
import ArrowRight from '../../../assets/arrow-right.svg';
import { HTMLDOMElement } from 'highcharts';
import classNames from 'classnames';
import ModalNewsletter from 'components/modalNewsletter/modalNewsletter';
import gsap from 'gsap';
import FactsQuellen from '../quellen/factsQuellen';

interface Props {
  facts: factsDataType[];
  maxFacts: number;
  backgroundColor?: string;
  hasCustomColor?: boolean;
  backgroundColorCustom?: string;
}

export const FactsGallery: React.FC<Props> = (props) => {
  // const ref = useRef(null);
  const sliderRef = useRef<any | null>(null);
  const [layer, setLayer] = useState<number>(0);
  const layerRef = useRef<number>(0);
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [autoPlay, setAutoPlay] = useState(true);


  useEffect(() => {
    setAutoPlay(window.innerWidth > 768 ? 1 : false);
  }, []);

  const flickityOptions = {
    draggable: true,
    // wrapAround: true,
    prevNextButtons: false,
    cellAlign: 'left',
    // autoPlay,
    freeScroll: true,
    selectedAttraction: .025,
    friction: .45,
    wrapAround: true,
  };

  const loop = () => {
    const flk = sliderRef.current;
    flk.x -= 1,
    flk.integratePhysics(),
    flk.settle(flk.x)
  }

  useEffect(() => {
    // console.log('sliderRef', sliderRef.current);
    
    if (window && sliderRef.current) {
      // window.whatever = sliderRef.current;

      let interval = null;

      const start = () => {
        interval = setInterval(() => {
          loop();
        }, 17);
      }
      
      const stop = () => {
        clearInterval(interval);
      }

      const sliderElement = sliderRef.current.element;

      sliderElement.addEventListener('mouseenter', () => {
        stop();
      });

      sliderElement.addEventListener('mouseleave', () => {
        start();
      });

      start();
    }
  }, []);


  useEffect(() => {
    if (!modalShown && layer === 5) {
      setModalShown(true);
      setModalOpen(true);
      // newsletter / modal / popup
    }
  }, [layer, modalShown]);

  const handleScrollToAllFacts = () => {
    setModalOpen(false);
    const menu = document.getElementsByClassName('menu')[0];
    const navHeight = menu?.clientHeight || 0;
    gsap.to(window, {
      duration: 0.4,
      scrollTo: window.innerHeight - navHeight,
    });
  };

  const style = {};

  if (props.hasCustomColor) {
    style['--background-color'] = props.backgroundColorCustom;
  }

  return (
    <div className={"facts-gallery facts-gallery--" + (props.hasCustomColor ? 'custom' : props.backgroundColor) }
      style={style}
    >
    {/* <pre>{JSON.stringify(props.facts, null, 2)}</pre> */}

      <div className="section-header">
        <h5 className="section-header__title">
          Fakten zur Schweizer Wirtschaftspolitik
        </h5>
      </div>

      <div className="facts-gallery__content">
        <Flickity
          className="facts-gallery__flickity"
          // flickityRef={c => c.flkty = c}
          flickityRef={(ref) => (sliderRef.current = ref)}
          options={flickityOptions}
          disableImagesLoaded={true}
          reloadOnUpdate={true}
        >
        {props.facts.map((fact, index) => {
            return (
              <FactsBlock {...fact.AcfFacts} hasShare={false} isLinked={true} maxFacts={props.maxFacts} key={index} />
            );
          })}
        </Flickity>
      </div>


      <div className="facts-gallery__all-facts">
        <Link to="/facts/" state={{ scrollTo: 'facts-overview' }}  className="facts-gallery__all-facts__button">
          <span className="facts-gallery__all-facts__button__icon">
            <ArrowRight />
          </span>
          <span className="facts-gallery__all-facts__button__text">Zur Übersicht</span>
        </Link>
      </div>
    </div>
  );
};
