import classNames from 'classnames';
import Img, { ImgProps } from 'components/img/img';
import { TransLink } from 'components/trans/transLink';
import { graphql } from 'gatsby';
import React from 'react';
import './heroSmall.scss';

interface Props {
  headline: string;
  image: ImgProps;
  link?: {
    url: string;
    target: string;
  };
}

const HeroSmall: React.FC<Props> = (props) => {
  // console.log(props);
  return (
    <div className={classNames('hero-small', { 'is-link': !!props.link })}>
      <div className="hero-small__inner">
        <Img {...props.image} />
        <h2 className={classNames('h1', 'hero-small-inner__headline')}>{props.headline}</h2>
        {!!props.link &&
          (props.link.target === '_blank' ? (
            <a href={props.link?.url} target="_blank" rel="norefferer" className="hero-small__link"></a>
          ) : (
            <TransLink to={props.link.url} className="hero-small__link" />
          ))}
      </div>
    </div>
  );
};

export default HeroSmall;

export const query = graphql`
  fragment HeroSmallBlock on WpAcfHerosmallBlock {
    AcfHeroSmall {
      headline
      image {
        ...BGImage
      }
    }
  }
`;
